.carouselButton {
    background: #000;
    color: var(--primary-text-color) !important;
    border: 0;
    font-size: 2.5rem;
    border-radius: 50%;
    opacity: .5;
    display: flex;
    justify-content: center;
    padding: 0;
}

.carouselButton+.carouselButton {
    margin-left: 1rem;
}

.carouselButton:hover,
.carouselButton:focus,
.carouselButton:focus-visible {
    opacity: .9 !important;
}