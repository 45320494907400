@import url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i);

body {
    --primary-text-color: #ffffff;
    --primary-text-hover-color: #c1c1c1;
    --dark-primary-text-color: #232323;
    --secondary-text-color: #767676;
    --header-background-color: #232323;
    --footer-background-color: #2e2e2e;
    --parallax-overlay-background-color: #232323;
    --parallax-bright-overlay-background-color: #ffffff;
    --link-text-color: #e9344a;
    --link-hover-text-color: #c03;
    --secondary-link-text-color: #149dcc;
    --secondary-link-hover-text-color: #149dcc;
    --white-outline-button-color: #ffffff;
    --white-outline-button-hover-background-color: #ffffff;
    --white-outline-button-hover-text-color: #232323;
    --main-color: #e9344a;
    --main-hover-color: #c03;
    --control-focus-border-color: rgba(255, 51, 102);
    --control-focus-shadow-color: rgba(255, 51, 102, 0.3);
    --close-dialog-button-color: #c1c1c1;
    --close-dialog-button-hover-color: #767676;
    --scroll-arrow-background-color: #505050;

    margin: 0;
}

.app-wrapper {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 1rem;
    font-weight: 400;

    color: var(--primary-text-color);
    white-space: pre-line;
    overflow: hidden;

}

h1,
h2,
h3,
h4,
h5,
h6,
a {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    line-height: 1.1;
}

section {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

section,
footer {
    scroll-margin-top: 4rem;
}

.home-section {
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    font-size: 1.5rem;
    line-height: 1.6;
    padding-top: 4.5rem;
}

.parallax-overlay-opacity-50,
.parallax-overlay-opacity-80,
.parallax-bright-overlay {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: var(--parallax-overlay-background-color);
}

.parallax-overlay-opacity-50 {
    opacity: 0.5;
}

.parallax-overlay-opacity-80 {
    opacity: 0.8;
}

.parallax-bright-overlay {
    opacity: 0.7;
    background-color: var(--parallax-bright-overlay-background-color);
}

h1 {
    font-size: 2.2rem !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

h2 {
    font-size: 3rem !important;
    font-weight: 300 !important;
}

h3 {
    font-size: 1.5rem !important;
}

h4 {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.subtitle-text {
    font-weight: 300 !important;
}

.btn {
    transition: all .3s ease-in-out !important;
}

.btn.btn-white-outline {
    border-radius: 100px;
    padding: 1rem 3rem;
    border-width: 2px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0.4rem 0.8rem !important;
}

.btn.btn-white-outline,
.btn.btn-white-outline:focus {
    background: transparent;
    border-color: var(--white-outline-button-color);
    color: var(--white-outline-button-color);
    box-shadow: none;
}

.btn.btn-white-outline:hover,
.btn.btn-white-outline:active,
.btn.btn-white-outline:focus-visible {
    background-color: var(--white-outline-button-hover-background-color) !important;
    color: var(--white-outline-button-hover-text-color) !important;
    border-color: var(--white-outline-button-hover-background-color) !important;
}

.btn.btn-primary-solid {
    border-radius: 100px;
    padding: .7rem 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    border: none;
}

.btn.btn-primary-solid,
.btn.btn-primary-solid:focus {
    color: var(--primary-text-color);
    background-color: var(--main-color);
    box-shadow: none;
}

.btn.btn-primary-solid:hover,
.btn.btn-primary-solid:active,
.btn.btn-primary-solid:focus-visible {
    background-color: var(--main-hover-color) !important;
    color: var(--primary-text-color) !important;
}

.features-card {
    background: transparent;
}

.bright-text {
    color: var(--primary-text-color);
}

.dark-text {
    color: var(--dark-primary-text-color);
}

.secondary-text {
    color: var(--secondary-text-color);
}

.primary-link {
    color: var(--link-text-color);
    text-decoration: none;
    font-weight: 400;
}

.primary-link:hover {
    color: var(--link-hover-text-color)
}

.secondary-link,
.subtitle-text a {
    color: var(--secondary-link-text-color);
    text-decoration: none;
    font-weight: 400;
}

.secondary-link:hover,
.subtitle-text a:hover {
    color: var(--secondary-link-hover-text-color);
}

.projects-card>img {
    width: 100%;
    height: 240px;
}

.google-map {
    height: 25rem;
}

.google-map iframe {
    width: 100%;
    height: 100%;
}

.socials {
    display: flex;
    justify-content: center;
}

.socials a {
    transition: all .35s;
    color: var(--secondary-text-color);
    font-size: 1.3rem;
}

.socials a:hover {
    color: var(--primary-text-color);
}

.footer {
    background-color: var(--footer-background-color);
    padding-top: 4rem;
    padding-bottom: 1rem;
}


.jarallax {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -100 !important;
}

.jarallax>.jarallax-img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.alice-carousel__stage-item>img {
    max-width: 100%;
    max-height: 100%;
}

.go-features-arrow {
    position: absolute;
    bottom: 2rem;
    right: 50%;
    transform: translate(50%, 0);
    background: var(--scroll-arrow-background-color);
    color: var(--primary-text-color) !important;
    font-size: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 0;
    opacity: .5;
}

.go-features-arrow svg {
    animation-duration: 1.5s;
    animation-name: slideTopDown;
    animation-iteration-count: infinite;
}

@keyframes slideTopDown {
    0% {
        transform: translateY(4%);
    }

    50% {
        transform: translateY(-4%);
    }

    100% {
        transform: translateY(4%);
    }
}

.request-quote-dialog .modal-header {
    justify-content: center;
    padding-top: 1.5rem !important;
    padding-bottom: .8rem !important;
}

.request-quote-dialog .modal-header .modal-close {
    position: absolute;
    top: .7rem;
    right: .5rem;
    padding: 0;
    color: var(--close-dialog-button-color);
    border: none;
    background: none;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
}

.request-quote-dialog .modal-header .modal-close:hover,
.request-quote-dialog .modal-header .modal-close:focus {
    color: var(--close-dialog-button-hover-color);
}

.request-quote-dialog .modal-header .modal-title {
    color: var(--main-color);
    font-size: 1.5rem;
}

.modal-header .btn-close:focus {
    box-shadow: none;
}

.modal-content {
    --bs-modal-border-width: 0;
}

.modal-content .btn {
    font-size: .9rem !important;
}

.form-control:focus {
    border-color: var(--control-focus-border-color) !important;
    box-shadow: 0 0 0 0.25rem var(--control-focus-shadow-color) !important;
}

.spinner-border {
    color: var(--main-color);
}

.modal .invisible .btn {
    transition: none !important;
}

.primary-icon {
    color: var(--secondary-link-text-color);
    fill: var(--secondary-link-text-color);
}

/*             */
/* Adaptivness */
/*             */
@media (max-width: 767px) {
    .home-section {
        font-size: 1.2rem !important;
        line-height: 1.7rem !important;
    }

    .btn {
        font-size: 0.75rem !important;
        word-break: break-word;
    }

    h1 {
        font-size: 1.4rem !important;
        line-height: 2.2rem !important;
    }

    h2 {
        font-size: 2rem !important;
        line-height: 2.8rem !important;
    }

    h3 {
        font-size: 1.2rem !important;
        line-height: 1.7rem !important;
    }

    .go-features-arrow {
        display: none;
    }
}

@media (min-width: 1400px) {
    .app-wrapper .container {
        max-width: 1140px;
    }
}