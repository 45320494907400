.goHomeArrow {
    position: fixed;
    bottom: 2rem;
    right: 50%;
    transform: translate(50%, 0);
    background: var(--header-background-color);
    color: var(--primary-text-color) !important;
    border: 0;
    font-size: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 0;
    opacity: .4;
    transition: all .3s;
}
  
.goHomeArrow:hover,
.goHomeArrow:focus,
.goHomeArrow:focus-visible {
    opacity: .7;
}

@media (min-width: 768px) {
    .goHomeArrow {
        right: 3rem;
        transform: none;
    }
}