.headerContainer {
    background-color: var(--header-background-color);
}

.headerAtTopContainer {
    background-color: transparent;
}

.toggler:global(.navbar-toggler) {
    width: 31px;
    height: 18px;
    cursor: pointer;
    top: 30px;
    right: 1rem;
    padding: 0;
    position: absolute;
    border: 0;
}

.toggler:global(.navbar-toggler):focus, .toggler:global(.navbar-toggler):focus-visible {
    outline: 0;
    box-shadow: none;
}

.headerContainer .toggler:global(.navbar-toggler) {
    top: 30px;
}

.hamburger {
    transition: all .2s;
}

.hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: var(--primary-text-color);
}

.toggler:global(.collapsed) .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.toggler:global(.collapsed) .hamburger span:nth-child(2), .toggler:global(.collapsed) .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.toggler:global(.collapsed) .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.toggler:global(:not(.collapsed)) .hamburger span:nth-child(1), .toggler:global(:not(.collapsed)) .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.toggler:global(:not(.collapsed)) .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.toggler:global(:not(.collapsed)) .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.headerLogo {
    transition: all .3s ease-in-out;
    margin-right: .4rem;
}

.headerAtTopContainer .headerLogo {
    height: 3.8rem;
}

.headerContainer .headerLogo {
    height: 3rem;
}

.navbarExpanded {
    background-color: var(--header-background-color);
}

.navigation :global(.nav-link) {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
}

.navigation :global(.nav-link) span {
    line-height: 1.7rem;
    padding-top: .1em;
}

.navigation svg {
    font-size: 1.7rem;
}

.navigation :global(.navbar-nav .nav-link), .navigation :global(.navbar-nav .nav-link.active) {
    color: var(--primary-text-color);
}

.navigation :global(.navbar-nav .nav-link):hover {
    color: var(--primary-text-hover-color);
}

.navigation :global(.nav-link) svg {
    margin-right: .2em;
}

nav.navbar {
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (min-width: 992px)
{
    nav.navbar {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

:global(.navbar-brand) {
    height: 61px;
}

.contactUsButton:global(.btn-white-outline) {
    padding: 0.4rem 1.5rem;
}

.changeLocaleButton:global(.btn) {
    background-color: var(--link-text-color);
    border: 0;
    font-weight: 500;
    border-radius: 100px;
    padding: 0.3rem 1.5rem;
    outline: none !important;
    box-shadow: none !important;
    margin: 0.4rem 0.8rem!important;
}

.changeLocaleButton:global(.btn):hover, .changeLocaleButton:global(.btn):active, .changeLocaleButton:global(.btn):focus, .changeLocaleButton:global(.btn):focus-visible {
    background-color: var(--link-hover-text-color) !important;
}